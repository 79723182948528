import { MPCITouchPoint } from "../typings/TouchPoint";
import { MPCCallLimiter } from "../helpers/call-limiter";
import { NlVanillajax, INlVanillajaxParams, ContentType, HtmlResult } from "../helpers/vanillajax";
import { MPCIFlyoutContentController } from "../typings/iFlyoutContentController";
import { MPCFlyoutContentController } from "../PCF/flyout-content-controller";
import { MPCEventBus } from "../helpers/event-bus";
import { MpcApiUrlHelperModule } from "../helpers/MpcApiUrlHelper";
import { MPCDeviceDetectionHelper } from "../helpers/deviceDetectionHelper";
const ScaleIcon2021 = require("../../images/scale-2021.svg") as string;
const ScaleEmptyIcon = require("../../images/scale-empty.svg") as string;

export module MPCTileScaleButton {
    import ITouchPoint = MPCITouchPoint.ITouchPoint;
    import EventBus = MPCEventBus.EventBus;
    import DeviceDetectionHelper = MPCDeviceDetectionHelper.DeviceDetectionHelper;

    export class TileScaleButton implements ITouchPoint {
        private markerElement: HTMLElement;
        private touchPointElement: HTMLElement;
        private slug: string;
        private origin: string;
        private salesArticleVariantKey: string;
        private displayedInCategoryNavKey: string;
        private isMobileShop: boolean;
        private iFlyoutContentController: MPCIFlyoutContentController.IFlyoutContentController;
        private eventBus: EventBus;
        private deviceDetectionHelper : DeviceDetectionHelper;

        constructor(element: HTMLElement, salesArticleVariantKey: string, slug: string, origin: string, displayedInCategoryNavKey: string, prerendered: boolean = false) {
            this.deviceDetectionHelper = DeviceDetectionHelper.instance;
            this.isMobileShop = this.deviceDetectionHelper.isMobileShop();
            this.iFlyoutContentController = MPCFlyoutContentController.FlyoutContentController.instance;
            this.eventBus = MPCEventBus.EventBus.instance;
            this.salesArticleVariantKey = salesArticleVariantKey;
            this.displayedInCategoryNavKey = displayedInCategoryNavKey;
            this.slug = slug;
            this.origin = origin;

            if (prerendered) {
                this.touchPointElement = element;

                if (!this.touchPointElement) {
                    return;
                }

                this.initialize();
            } else {
                this.markerElement = element;
                this.getTouchPoint();
            }
        }

        update(oldSavKey: string, newSavKey: string): void {
            if (!this.touchPointElement) {
                return;
            }

            if (!oldSavKey) {
                // backwards compatibility - can be removed after ATS is deployed on PROD
                const selectedVariantElement: HTMLElement = this.touchPointElement.closest('.article-tile')
                    .querySelector('.colors .selected img');
                const thisButtonIsForSavKey: string = selectedVariantElement ? selectedVariantElement.getAttribute('data-colorid') : null;

                if (thisButtonIsForSavKey != newSavKey) {
                    // this event is received by all touchpoints on the site, 
                    // on categorypage, unrelated touchpoints need to ignore this
                    return;
                }
                this.salesArticleVariantKey = newSavKey;
                this.toggle();
            } else {
                // new way
                if (this.salesArticleVariantKey != oldSavKey) {
                    return;
                }

                this.salesArticleVariantKey = newSavKey;
                this.toggle();
            }
        }

        private getTouchPoint(): void {
            let params: INlVanillajaxParams = <INlVanillajaxParams>{};
            const scopeClass = this.markerElement.classList.contains("scope-ats")
                ? "ats"
                : "";
            params.url = MpcApiUrlHelperModule.MpcApiUrlHelper.GetApiUrl() + "TouchPoint/ForArticleTile/";
            params.url += "?context.SeoSlug=" + this.slug;
            params.url += "&context.SavKey=" + this.salesArticleVariantKey;
            params.url += "&context.ItemOrigin=" + this.origin;
            params.url += "&context.ScopeClass=" + scopeClass;
            if(this.displayedInCategoryNavKey)
                params.url += "&context.DisplayedInCategoryNavKey=" + this.displayedInCategoryNavKey;
            params.contentType = ContentType.Plain;
            new NlVanillajax(params).getWithPromise()
                .then((response: HtmlResult) => {
                    let container: HTMLElement = document.createElement('div');
                    container.innerHTML = response.Html;
                    this.touchPointElement = container.querySelector(".touchpoint");  //there might be text nodes in there, so not childNodes[0]
                    if (!this.touchPointElement) {
                        throw new Error("no touchpoint returned from service");
                    }
                    this.markerElement.parentNode.replaceChild(this.touchPointElement, this.markerElement);
                    this.initialize();
                })
                .catch(() => {
                    //empty response indicates this article has disabled comparing for some reason
                    if (this.markerElement) {
                        this.markerElement.remove();
                    } else if (this.touchPointElement) {
                        this.touchPointElement.remove();
                    }
                });
        }


        private initialize(): void {
            this.toggle();
            this.touchPointElement.querySelector('.comparison-empty-icon').innerHTML = ScaleEmptyIcon + ScaleIcon2021;
            this.touchPointElement.querySelector('.comparison-icon').innerHTML = ScaleIcon2021;

            this.touchPointElement.addEventListener('click', MPCCallLimiter.CallLimiter.debounce(() => {
                this.updateFlyout();
            }, 1000, true).bind(this));
            this.eventBus.subscribe("flyout.product-removed", (salesArticleVariantKey: string) => {
                if (salesArticleVariantKey === this.salesArticleVariantKey) {
                    this.touchPointElement.classList.remove('active');
                }
            });
            this.eventBus.subscribe(["flyout.product-added", "flyout.product-replaced"], (salesArticleVariantKey: string) => {
                if (salesArticleVariantKey === this.salesArticleVariantKey) {
                    this.touchPointElement.classList.add('active');
                }
            });

            if (this.isMobileShop) {
                this.eventBus.subscribe("mobile-toolbar-button.clicked", this.updateVisibility.bind(this));
            }
        }

        private updateVisibility(): void {
            if (this.touchPointElement.classList.contains("ats")) {
                if (this.touchPointElement.parentElement.classList.contains('fas_hidden')) {
                    this.touchPointElement.parentElement.classList.remove('fas_hidden');
                } else {
                    this.touchPointElement.parentElement.classList.add('fas_hidden');
                }
            } else {
                if (this.touchPointElement.classList.contains("visible")) {
                    this.touchPointElement.classList.remove("visible");
                } else {
                    this.touchPointElement.classList.add("visible");
                }
            }
        }

        private updateFlyout(): void {
            if (!this.iFlyoutContentController.checkIsProductAlreadyAdded(this.salesArticleVariantKey)) {
                this.iFlyoutContentController.addProduct(this.salesArticleVariantKey, this.slug, this.origin);
            } else {
                this.iFlyoutContentController.removeProductTP(this.salesArticleVariantKey);
            }
        }

        private toggle(): void {
            if (this.iFlyoutContentController.checkIsProductAlreadyAdded(this.salesArticleVariantKey)) {
                this.touchPointElement.classList.add('active');
            } else {
                this.touchPointElement.classList.remove('active');
            }
        }
    }
}