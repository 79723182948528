import { MPCITouchPoint } from "../typings/TouchPoint";
import { MPCITouchPointIdentifier } from "../typings/TouchPointIdentifier";
import { MPCTouchPointIdentifier } from "./TouchPointIdentifier";
import { MPCCompareButton } from "./CompareButton";
import { MPCTileScaleButton } from "./TileScaleButton";
import { MPCTileScaleButtonEx } from "./TileScaleButtonEx";
import { MPCMobileToolbarButton } from "./MobileToolbarButton";
import { MPCFlyoutContentController } from '../PCF/flyout-content-controller';
import { MpcSettingsModule } from "../helpers/MpcSettings";
import { MpcApiUrlHelperModule } from "../helpers/MpcApiUrlHelper";
const ScaleIcon2021 = require("../../images/scale-2021.svg") as string;
const ScaleEmptyIcon = require("../../images/scale-empty.svg") as string;
import axios, { AxiosError, AxiosResponse } from 'axios';

export module MPCTouchPointFactory {
    import ITouchPoint = MPCITouchPoint.ITouchPoint;
    import TouchPointPayload = MPCITouchPoint.TouchPointPayload;
    import ITouchPointIdentifier = MPCITouchPointIdentifier.ITouchPointIdentifier;
    import ServiceCallName = MPCITouchPointIdentifier.ServiceCallName;
    import TouchPointIdentifier = MPCTouchPointIdentifier.TouchPointIdentifier;
    import CompareButton = MPCCompareButton.CompareButton;
    import TileScaleButton = MPCTileScaleButton.TileScaleButton;
    import TileScaleButtonEx = MPCTileScaleButtonEx.TileScaleButtonEx;
    import MobileToolbarButton = MPCMobileToolbarButton.MobileToolbarButton;
    import MpcSettings = MpcSettingsModule.MpcSettings;

    export class TouchPointFactory {
        private static _instance: TouchPointFactory;
        private touchPointIdentifier: ITouchPointIdentifier;
        private touchPoints: ITouchPoint[];
        private isMobileView = window.innerWidth <= 1199;       
        private isNewDetailsPage: HTMLElement = document.querySelector('.pdp-details_page_container'); // only for new details page:
        private compareButtonSavKeySelector: string = this.isMobileView ?
            'input[name=SalesarticleVariantKey]' : 'input[name=SalesArticleVariantKey]';

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        private constructor() {
            this.touchPointIdentifier = TouchPointIdentifier.instance;
            this.touchPoints = [];

            if (window.shell) {
                this.initializeEvents();

                // we have to init it because of variety of resolution on tablet devices
                MobileToolbarButton.instance;
            }
        }

        private initializeEvents() {  
            if(this.isNewDetailsPage) {
                this.CreateTouchPointsForNewPDP();
            }
            else {
                window.shell.subscribeTo("ManualProductComparison.CreateTouchPoints", (scope: HTMLElement) => {
                    this.createByMarkersPayload(scope);
                }, "ManualProductComparison.CreateTouchPoints");
            }

            // for legacy category page:
            window.shell.subscribeTo("ManualProductComparison.CreateScaleIconTouchPoints", (tile: HTMLElement) => {
                const salesArticleVariantKey: string =
                    tile.getAttribute('data-selected-colorid');
                this.createByMarkers(salesArticleVariantKey, "mpc-scale-tile", tile);
            }, "ManualProductComparison.CreateScaleIconTouchPoints");

            // for new ATS and search page:
            window.shell.subscribeTo("ManualProductComparison.CreateTouchPointsBatch", (placeHoldersData: {savKey: string;
                seoSlug: string; displayedInCategoryNavKey: string;}[]) => {
                this.createByMarkersEx(placeHoldersData, "mpc-scale-tile");
            }, "ManualProductComparison.CreateTouchPointsBatch");

            window.shell.subscribeTo("ManualProductComparison.CreateCompareButtonTouchPoints", (form: HTMLElement) => {
                let savKeyElement = form.querySelector(this.compareButtonSavKeySelector);
                if (!savKeyElement) {
                    //TODO on mobile after clicking basket icon, order modal opens, but it contains no compare button... 
                    return;
                }
                const salesArticleVariantKey: string = savKeyElement.getAttribute('value');
                this.createByMarkers(salesArticleVariantKey, "mpc-compare-button", form);
            }, "ManualProductComparison.CreateCompareButtonTouchPoints");

            window.shell.subscribeTo("ManualProductComparison.UpdateTouchPoints", (payload: { oldSavKey: string, newSavKey: string }) => {
                if (typeof payload == 'string') {
                    // backwards compatibility - can be removed after ATS is deployed on PROD
                    payload = { oldSavKey: null, newSavKey: payload };
                }
                for (const touchPoint of this.touchPoints) {
                    touchPoint.update(payload.oldSavKey, payload.newSavKey);
                }
            }, "ManualProductComparison.UpdateTouchPoints");
            
            document.addEventListener("DOMContentLoaded",
                () => {
                    this.initializePrerenderedMobileTouchPoints();
                });
            
            document.addEventListener("DOMContentLoaded",
                () => {
                    this.initializeCompareLinkTouchPoints();
                });
        }

        // only for new details page:
        private CreateTouchPointsForNewPDP() {
            // Necessary for AAS fragment:
            window.shell.subscribeTo("ManualProductComparison.CreateTouchPoints", (scope: HTMLElement) => {
                this.createByMarkersPayload(scope);
            }, "ManualProductComparison.CreateTouchPoints");

            const newBtnLst: NodeListOf<HTMLElement> = this.isNewDetailsPage.querySelectorAll('.mpc-compare-btn');
            if (!newBtnLst || newBtnLst.length === 0) {
                return;
            }

            for (let i = 0; i < newBtnLst.length; i++) {
                const newBtn = newBtnLst[i];
                const salesArticleVariantKey: string = newBtn.getAttribute('data-savkey');
                const slug: string = newBtn.getAttribute('data-slug');
                const origin: string = newBtn.getAttribute('data-item-origin');

                new CompareButton(null, salesArticleVariantKey, slug, origin, '', newBtn);
            }
        }

        private initializePrerenderedMobileTouchPoints(): void {
            const touchPoints: NodeListOf<HTMLElement> = this.touchPointIdentifier.getTileScaleTouchPoints();
            const touchPointPlaceholders: NodeListOf<HTMLElement> = this.touchPointIdentifier.getMobileTouchPointPlaceholders();
            const savKeyToPlaceholder: {[id:string] : HTMLElement} = {};
            for (const touchPointPlaceholder of Array.prototype.slice.call(touchPointPlaceholders)) {
                savKeyToPlaceholder[touchPointPlaceholder.getAttribute("data-savkey")] = touchPointPlaceholder;
            }
            let parentElement: HTMLElement;
            if (touchPoints.length > 0) { 
                parentElement = touchPoints[0].parentElement;
            }
            for (const touchPoint of Array.prototype.slice.call(touchPoints)) {

                touchPoint.querySelector('.comparison-empty-icon').innerHtml = ScaleEmptyIcon;
                touchPoint.querySelector('.comparison-icon').innerHtml = ScaleIcon2021;

                const slug: string = touchPoint.dataset.slug;
                const salesArticleVariantKey: string = touchPoint.dataset.savkey;
                const itemOrigin: string = touchPoint.getAttribute("data-item-origin");
                const displayedInCategoryNavKey: string = touchPoint.getAttribute("data-displayed-in-category-navkey");
                if (slug && salesArticleVariantKey) {
                    this.touchPoints.push(new TileScaleButton(touchPoint, salesArticleVariantKey, slug, itemOrigin, displayedInCategoryNavKey, true));
                }

                //move in DOM (they are batch-rendered below the article list and need to be sorted into their article tile)
                const placeholder = savKeyToPlaceholder[touchPoint.getAttribute("data-savkey")];
                if (placeholder) {
                    placeholder.parentNode.replaceChild(touchPoint, placeholder);
                }
            }
            if (parentElement) {
                const currentCategoryNavKey = parentElement.getAttribute("data-category-navkey");
                MpcSettings.instance.MobileCategoryNavKey = currentCategoryNavKey;
                parentElement.remove();
            }
        }

        private initializeCompareLinkTouchPoints(): void {
            const flyoutContentController = MPCFlyoutContentController.FlyoutContentController.instance;
            const compareLinks = this.touchPointIdentifier.getCompareLinks();
            for (let i = 0; i < compareLinks.length; i++) {
                const compareLink = compareLinks[i];
                const htmlIndex = compareLink.href.indexOf(".html");
                const url = compareLink.href.substring(0, htmlIndex);
                const splitted = url.split("-").reverse();
                const maNo = parseInt(splitted[2]);
                const colorCode = parseInt(splitted[0]);
                compareLink.addEventListener('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    flyoutContentController.addProductByCompareLink(maNo, colorCode);
                });
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private isAxiosError(error: any): error is AxiosError {
            return typeof error.toJSON !== 'undefined';
        }

        private createByMarkersEx(articlesContext: {savKey: string; seoSlug: string;
            displayedInCategoryNavKey: string;}[], type?: ServiceCallName): void {
            
            // Call MPC endpoint:
            axios.post(MpcApiUrlHelperModule.MpcApiUrlHelper.GetApiUrl() + "TouchPoint/IsBatchComparable/", 
            articlesContext, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response: AxiosResponse) => {
                if (response.status >= 200 && response.status < 300 && response.data && response.data.length > 0) {
                    const validSavKeys = response.data;

                    // Create TP from valid sav keys array:
                    const markers: HTMLElement[] = this.touchPointIdentifier.getMarkersFromDomEx(type, validSavKeys);

                    for (const markerElement of markers) {
                        if (markerElement) {
                            this.touchPoints.push(this.createTouchPointEx(markerElement));
                        }
                    }
                }
            })
            .catch((error: Error | AxiosError) => {
                const errorMsg = this.isAxiosError(error)
                    ? error.message
                    : `${error.name}: ${error.message}`;
                const errorStack = this.isAxiosError(error)
                    ? JSON.stringify(error.toJSON())
                    : error.stack;

                // eslint-disable-next-line no-console
                console.warn(
                    `fetch() failed with "${errorMsg}". Full error:\n${errorStack}`
                );
            });
        }

        private createByMarkers(salesArticleVariantKey: string, type?: ServiceCallName, scope?: HTMLElement): void {
            const markers: NodeListOf<HTMLElement> = this.touchPointIdentifier.getMarkersFromDom(scope, type);
            for (const markerElement of Array.prototype.slice.call(markers)) {
                this.touchPoints.push(this.createTouchPoint(markerElement, salesArticleVariantKey));
            }
        }

        // Todo: This will replace the createByMarkers method. Legacy frontends needs to be adjusted first before this refactor step
        private createByMarkersPayload(scope?: HTMLElement): void {
            const markers: NodeListOf<HTMLElement> = this.touchPointIdentifier.getMarkersFromDom(scope, "ESCID.ESPP.ManualProductComparison");
            for (const markerElement of Array.prototype.slice.call(markers)) {
                const markerPayloadObject: TouchPointPayload = JSON.parse(markerElement.dataset.payload);

                if (markerPayloadObject) {}
                    this.touchPoints.push(this.createTouchPointByPayloadObject(markerElement, markerPayloadObject));
            }
        }

        private createTouchPointEx(marker: HTMLElement): ITouchPoint {
            // const type: ServiceCallName = marker.dataset.serviceCall as ServiceCallName;
            const slug: string = marker.dataset.slug;
            const origin: string = marker.getAttribute("data-item-origin");
            const displayedInCategoryNavKey: string = marker.getAttribute("data-displayed-in-category-navkey");
            const salesArticleVariantKey: string = marker.getAttribute("data-selected-colorid");

            const touchPoint = new TileScaleButtonEx(marker, salesArticleVariantKey, slug, 
                origin, displayedInCategoryNavKey, MobileToolbarButton.instance.getTBW_Mlt());
            return touchPoint;
        }

        private createTouchPoint(marker: HTMLElement, salesArticleVariantKey: string): ITouchPoint {
            const type: ServiceCallName = marker.dataset.serviceCall as ServiceCallName;
            const slug: string = marker.dataset.slug;
            const origin: string = marker.getAttribute("data-item-origin");
            const displayedInCategoryNavKey: string = marker.getAttribute("data-displayed-in-category-navkey");

            let touchPoint: ITouchPoint = null;

            if (type === "mpc-compare-button") {
                touchPoint = new CompareButton(marker, salesArticleVariantKey, slug, origin, displayedInCategoryNavKey, null);
            } else if (type === "mpc-scale-tile") {
                touchPoint = new TileScaleButton(marker, salesArticleVariantKey, slug, origin, displayedInCategoryNavKey);
            }

            return touchPoint;
        }

        // Todo: This will replace the createTouchPoint method. Legacy frontend needs to be adjusted first before this refactor step
        private createTouchPointByPayloadObject(marker: HTMLElement, dataObject: TouchPointPayload): ITouchPoint {
            const type: ServiceCallName = dataObject.type;
            const salesArticleVariantKey: string = dataObject.savKey;
            const slug: string = dataObject.slug;
            const origin: string = marker.getAttribute("data-item-origin");
            const displayedInCategoryNavKey: string = marker.getAttribute("data-displayed-in-category-navkey");

            let touchPoint: ITouchPoint = null;

            if (type === "mpc-compare-button") {
                touchPoint = new CompareButton(marker, salesArticleVariantKey, slug, origin, displayedInCategoryNavKey, null);
            } else if (type === "mpc-scale-tile") {
                touchPoint = new TileScaleButton(marker, salesArticleVariantKey, slug, origin, displayedInCategoryNavKey);
            }

            return touchPoint;
        }
    }
}

if(window.shell)
    MPCTouchPointFactory.TouchPointFactory.instance;