export module MPCCallLimiter {
    export abstract class CallLimiter {
        static debounce(callback: Function, delay: number, immediate: boolean = false): Function {
            let timer: number;
            return function () {
                const context = this;
                const args: IArguments = arguments;

                const delayedCall: Function = function () {
                    timer = null;
                    if (!immediate) {
                        callback.apply(context, args);
                    }
                };

                const instantCall: boolean = immediate && !timer;
                clearTimeout(timer);
                timer = setTimeout(delayedCall, delay);

                if (instantCall) {
                    callback.apply(context, args);
                }
            };
        }

        static throttle(callback: Function, interval: number, leadCall: boolean = true, trailCall: boolean = true): Function {
            let blockExecution = leadCall;
            let resetTimer: Timer;

            return function () {
                const context = this;
                const args: IArguments = arguments;

                if (leadCall && !resetTimer) {
                    callback.apply(context, args);
                    blockExecution = false;

                    if (!trailCall) {
                        resetTimer = setTimeout(function () {
                            resetTimer = null;
                        }, interval);
                    }
                }

                if (!blockExecution && trailCall) {
                    blockExecution = true;

                    setTimeout(function () {
                        callback.apply(context, args);
                        blockExecution = false;
                    }, interval);

                    clearTimeout(resetTimer);
                    resetTimer = setTimeout(function () {
                        resetTimer = null;
                    }, interval + 250);
                }
            };
        }
    }

    type Timer = ReturnType<typeof setTimeout>;
}
