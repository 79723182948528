import { ProductComparisonModel } from "../../../../DomainObjects/Dtos/Product.generated";

export module MPCComparePageButtonUrlUpdater {
    export class ComparePageButtonUrlUpdater {
        private static _instance: ComparePageButtonUrlUpdater;
        private scope: HTMLElement;
        private comparisonPageBaseUri: string;

        public init(scope: HTMLElement): void {
            this.scope = scope;
            this.comparisonPageBaseUri = scope.getAttribute('data-comparisonpage-baseurl');
        }

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        private _comparePageUrl:string = "";
        get ComparePageUrl():string {
            return this._comparePageUrl;
        }

        public onUpdatedModel(model : ProductComparisonModel): void {
            let result: string = "";

            if(model && model.activeNavigationTab) {
                const activeNavigationTab = model.activeNavigationTab;

                let comparisonPageUri: string = '?category=' + encodeURIComponent(activeNavigationTab);

                for (const storageProduct of model.products) {
                    if (storageProduct.categoryName === activeNavigationTab) {
                        comparisonPageUri = comparisonPageUri + '&savKeys=' + storageProduct.articleId.salesArticleVariantKey;
                    }
                }

                result = comparisonPageUri;
            }

            this._comparePageUrl = this.comparisonPageBaseUri+result;

            if (this.scope) {
                const  links = this.scope.querySelectorAll(".comparison-page-link");

                for (let link of <HTMLElement[]><any>links) {
                    link.setAttribute('href', this._comparePageUrl);
                }
            }
        }
    }
}