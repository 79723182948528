import { MPCIFlyoutContentController } from "../typings/iFlyoutContentController";
import { MPCFlyoutContent } from "./flyout-content";
import { MPCNotificationService } from "./notification-service";
import { MPCNavigationService } from "../helpers/NavigationService";
import { MPCDeviceDetectionHelper } from "../helpers/deviceDetectionHelper";
import { ProductComparisonModel, Product } from "../../../../DomainObjects/Dtos/Product.generated";
import { MPCComparePageButtonUrlUpdater } from "../helpers/comparePageButtonUrlUpdater";
import { MPCLocalStorageService } from "../local-storage-service";
import { MPCScopeDetection } from "../helpers/MpcScopeDetection";
import { MpcSettingsModule } from "../helpers/MpcSettings";
import { MPCErrorHandlerModule } from "../errorhandler";

const ArrowUpIcon = require("../../images/arrow-up.svg") as string;
const ArrowRightIcon = require("../../images/arrow-right-2021.svg") as string;

export module MPCFlyoutContentController {
    import IFlyoutContentController = MPCIFlyoutContentController.IFlyoutContentController;
    import NotificationService = MPCNotificationService.NotificationService;
    import FlyoutContent = MPCFlyoutContent.FlyoutContent;
    import NavigationService = MPCNavigationService.NavigationService;
    import DeviceDetectionHelper = MPCDeviceDetectionHelper.DeviceDetectionHelper;
    import ComparePageButtonUrlUpdater = MPCComparePageButtonUrlUpdater.ComparePageButtonUrlUpdater;
    import ProductComparisonModelManager = MPCLocalStorageService.ProductComparisonModelManager;
    import ScopeDetection = MPCScopeDetection.ScopeDetection;
    import MpcSettings = MpcSettingsModule.MpcSettings;
    import ErrorHandlerManager = MPCErrorHandlerModule.ErrorHandlerManager;

    export class FlyoutContentController implements IFlyoutContentController {
        private flyoutContentObject: FlyoutContent;
        private static _instance: FlyoutContentController;
        private flyoutContentContainer: HTMLElement;
        private readonly flyoutContentScope: HTMLElement;
        private notificationService: NotificationService;
        private navigationService: NavigationService;
        private flyoutHeader: HTMLElement;
        private deviceDetectionHelper: DeviceDetectionHelper;
        private comparePageButtonUrlUpdater: ComparePageButtonUrlUpdater;
        private productComparisonModelManager: ProductComparisonModelManager;
        private flyoutIconContainer: HTMLElement;
        
        static get instance() {
            return this._instance || (this._instance = new this());
        }
        
        constructor() {
            this.flyoutContentScope = document.querySelector('.pcf-flyout-content-scope');

            if (!this.flyoutContentScope) {
                return;
            }

            this.init();
        }
        
        private init(): void {
            this.flyoutIconContainer = document.querySelector('.pcf-scope');

            MpcSettings.instance.init(this.flyoutIconContainer);

            this.loadFlyoutIcons();
            this.deviceDetectionHelper = DeviceDetectionHelper.instance;
            this.notificationService = NotificationService.instance;
            this.notificationService.flyoutContentController = this;

            if(!this.deviceDetectionHelper.isMobileShop()) {
                this.comparePageButtonUrlUpdater = ComparePageButtonUrlUpdater.instance;
                this.comparePageButtonUrlUpdater.init(this.flyoutContentScope);
            }

            // check local storage
            this.productComparisonModelManager = ProductComparisonModelManager.instance;
            this.checkCurrentLocalStorage();
            
            this.flyoutContentContainer = this.flyoutContentScope.querySelector('.flyout');
            this.flyoutHeader = this.flyoutContentContainer.querySelector('.flyout-header');

            this.flyoutContentObject = new FlyoutContent();

            this.navigationService = NavigationService.instance;
            this.navigationService.init(this.flyoutHeader, 'data-pcf-navigation-key', this.flyoutContentObject);
            this.flyoutContentObject.init(this.flyoutContentContainer, this.navigationService, this);
            
            // close/show flyout events
            this.registerEventHandlers();
        }

        private loadFlyoutIcons(): void {
            const closeArrow: HTMLElement = this.flyoutContentScope.querySelector('.close-arrow');
            const compareButtonIcon: HTMLElement = this.flyoutContentScope.querySelector('.compare-button-icon');

            closeArrow.innerHTML = ArrowUpIcon;
            compareButtonIcon.innerHTML = ArrowRightIcon;
        }

        private registerEventHandlers(): void {
            const titleContainer: HTMLElement = this.flyoutHeader.querySelector('.title-container');
            const titleCloseFlyoutBtn: HTMLElement = titleContainer.querySelector('.close-flyout-btn');
            titleCloseFlyoutBtn.addEventListener('click', () => { this.handleCloseClickFromFlyoutFragment() });

            window.shell.subscribeTo('ESMKT.HeadR.FlyoutClosed', () => { this.cleanUpEmptyContent(); }, 'ESMKT.HeadR.FlyoutClosed');
        }

        public hideFlyoutContent(): void {
            this.flyoutContentContainer.classList.remove('pcf-show-flyout');
        }

        public handleFlyoutIconClick(): void {
            if (!this.flyoutContentContainer.classList.toggle('pcf-show-flyout')) {
                this.cleanUpEmptyContent();
            }
        }

        private handleCloseClickFromFlyoutFragment(): void {
            window.shell.publishTo('ESMKT.HeadR.ToggleFlyout', 'ManualProductComparison');
            this.cleanUpEmptyContent();
        }

        public refresh(model: ProductComparisonModel): void {
            this.flyoutContentObject.refresh(model);
            if (model && model.activeNavigationTab) {
                const newTabActivated: boolean = this.flyoutContentObject.setActiveNavigationTab(model.activeNavigationTab);
                
                // in case when user click go to category page from empty tab with removed all products. 
                // We will still have same active tab, because another one was not clicked yet.
                if(!newTabActivated) {
                    this.flyoutContentObject.updateContainerListVisibility(this.navigationService.markAnyNavigationTabActive());
                }
            }
        }

        public addProduct(salesArticleVariantKey: string, seoSlug: string, origin: string): void {
            this.flyoutContentObject.addProduct(salesArticleVariantKey, seoSlug, origin).then((product: Product) => {
                this.flyoutContentObject.setActiveNavigationTab(product.categoryName);
            });

            // show Pcf Scope:
            if (!this.deviceDetectionHelper.isMobileShop()) {
                window.shell.publishTo('ManualProductComparison.ShowPcfScope', undefined);
            }
        }

        public addProductByCompareLink(maNo: number, colorCode: number): void {
            this.flyoutContentObject.addProductByMasterArticleNo(maNo, colorCode, "content-page").then((product: Product) => {
                this.flyoutContentObject.setActiveNavigationTab(product.categoryName);
            });

            // show Pcf Scope:
            if (!this.deviceDetectionHelper.isMobileShop()) {
                window.shell.publishTo('ManualProductComparison.ShowPcfScope', undefined);
            }
        }

        public removeProductTP(salesArticleVariantKey: string): void {
            this.flyoutContentObject.removeProductTP(salesArticleVariantKey);
            
            this.cleanUpEmptyContent();
        }

        public updateProductsCounter(counterNumber: number): void {
            const productsCounter: HTMLElement = this.flyoutIconContainer.querySelector('.products-counter');
            
            let counter: number = Number(productsCounter.innerText);
            counter += counterNumber;
            
            productsCounter.innerText = counter.toString();
        }

        private cleanUpEmptyContent(): void {
            this.flyoutContentObject.removeEmptyContainerList();
            this.navigationService.removeEmptyNavigationTabs();

            if (this.isFlyoutContentEmpty()) {
                if (!this.deviceDetectionHelper.isMobileShop()) {
                    window.shell.publishTo('ManualProductComparison.HidePcfScope', undefined);
                }
            }
        }

        private isFlyoutContentEmpty(): boolean {
            return this.flyoutContentObject.isFlyoutContentEmpty();
        }

        private announceNavigationTabCleanUpOutsidePcp(productComparisonModel: ProductComparisonModel): void {
            const emptyNavigationTabs = productComparisonModel.emptyNavigationTabs;

            if (emptyNavigationTabs.length && ScopeDetection.hasScopes(false, true))
                this.productComparisonModelManager.setClearStoredEmptyNavigationTabs(true);
        }

        private checkCurrentLocalStorage(): void {
            this.productComparisonModelManager.getModel()
                .then((productComparisonModel: ProductComparisonModel) => {
                    if (productComparisonModel && productComparisonModel.products && productComparisonModel.products.length > 0) {
                        // init and update flyout:
                        this.refresh(productComparisonModel);
                    }
                    else {
                        // clear existing flyout content
                        this.refresh(null);
                    }
                    this.announceNavigationTabCleanUpOutsidePcp(productComparisonModel);
                });
        }

        public checkIsProductAlreadyAdded(salesArticleVariantKey: string): boolean {
            return this.productComparisonModelManager.checkIsProductAlreadyAdded(salesArticleVariantKey);
        }

    }
}

if (window.shell)
    MPCFlyoutContentController.FlyoutContentController.instance;