export module MPCDeviceDetectionHelper {
    export class DeviceDetectionHelper {
        private static _instance: DeviceDetectionHelper;
        private isTabletHandHeldDeviceEnvironment: boolean;
        private isMobileShopEnvironment: boolean;
        private isMicrosoftBrowserEnvironment: boolean;
        private sniffJson: HTMLElement;

        constructor() {
            this.init();
        }

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        private init(): void {
            this.isTabletHandHeldDeviceEnvironment =  
                document.documentElement.className.indexOf('tablet') >= 0 || document.documentElement.className.indexOf('handheld') >= 0;

            this.isMobileShopEnvironment =  
                document.documentElement.className.indexOf('mobileshop') >= 0;

            this.isMicrosoftBrowserEnvironment =
                document.documentElement.classList.contains("isMsie") || document.documentElement.classList.contains("isEdge");

            this.sniffJson = document.getElementById("sniff");
            if (!this.sniffJson)
                return;

            const sniffJsonResult = JSON.parse(this.sniffJson.innerHTML);
            let resultingClasses: string = '';
            if (sniffJsonResult.isTablet) {
                document.body.dataset.cap = "IsHandheld=true,IsLowendDevice=false";
                resultingClasses += ' tablet';
            }
            if (sniffJsonResult.isPhone) 
                resultingClasses = ' phone';
            if (sniffJsonResult.isHandheld) 
                resultingClasses += ' handheld';
            if (sniffJsonResult.isAndroid) 
                resultingClasses += ' android';
            if (sniffJsonResult.isIOS) 
                resultingClasses += ' ios';
            if (!resultingClasses)
                return;

            const htmlClasses: string = document.documentElement.className;
            document.documentElement.className = htmlClasses.replace('desktop', resultingClasses);

            if (sniffJsonResult.isMsie)
                document.documentElement.classList.add('isMsie');
            if (sniffJsonResult.isEdge)
                document.documentElement.classList.add('isEdge');
        }

        public isTabletHandHeldDevice(): boolean {
            return this.isTabletHandHeldDeviceEnvironment;
        }

        public isMobileShop(): boolean {
            return this.isMobileShopEnvironment;
        }

        public isMicrosoftBrowser(): boolean {
            return this.isMicrosoftBrowserEnvironment;
        }

        // removed because of ESPP-4706
        // public adaptLegacySniff(): void {
        //     const deviceDetector = new DeviceDetector();
        //     const device: DeviceDetector.DeviceDetectorResult = 
        //         deviceDetector.parse(window.navigator.userAgent);

        //         let resultingClasses: string = '';
        //         let sniffResult: string = '{';

        //         if (device.device.type === 'desktop')
        //             sniffResult += '"isDesktop": true';
        //         else 
        //             sniffResult += '"isDesktop": false';

        //         if (device.device.type === 'tablet') {
        //             document.body.dataset.cap = "IsHandheld=true,IsLowendDevice=false";
        //             resultingClasses = 'tablet';
        //             sniffResult += ', "isTablet": true';
        //         }
        //         else 
        //             sniffResult += ', "isTablet": false';

        //         if (device.device.model === 'iPad')
        //             sniffResult += ', "isIPad": true';
        //         else 
        //             sniffResult += ', "isIPad": false';
                
        //         sniffResult += '}';

        //         if (resultingClasses) {
        //             const htmlClasses: string = document.documentElement.className;
        //             document.documentElement.className = htmlClasses.replace('desktop', resultingClasses);
        //         }

        //         this.sniffJson.innerHTML = sniffResult;
        // }
    }
}