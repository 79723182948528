import { MpcSettingsModule } from "./MpcSettings";

export module MpcApiUrlHelperModule {    
    import MpcSettings = MpcSettingsModule.MpcSettings;

    export class MpcApiUrlHelper {
        public static GetApiUrl(): string {

            return "/" + MpcSettings.instance.languagePrefix + "/api/ManualProductComparison/";
        }
    }
}