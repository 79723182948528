export module MPCScrollLock {
    export class ScrollLock
    {
        private static _instance: ScrollLock;
        private bodyScrollPosition: number = 0;

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        public lock(): void {
            const webContainer = document.querySelector('.container');
            const mobileContainer = document.querySelector('.page-container');
            this.bodyScrollPosition = window.pageYOffset;
    
            if (webContainer || mobileContainer)
                document.body.setAttribute(
                    'style',
                    'overflow: hidden !important; position: fixed !important; top: -' +
                        this.bodyScrollPosition +
                        'px;'
                );
        }
    
        public unlock(): void {
            const webContainer = document.querySelector('.container');
            const mobileContainer = document.querySelector('.page-container');
    
            if (webContainer || mobileContainer) {
                document.body.removeAttribute('style');
                window.scrollTo(0, this.bodyScrollPosition);
            }
        }
    }
}