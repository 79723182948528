import { MPCLocalStorageService } from "../local-storage-service";
import { MPCIFlyoutController } from "../typings/iFlyoutController";
import { MPCStickyFlyOutNotificationService } from "./stickyFlyOutNotificationService";
import { MpcSettingsModule } from "../helpers/MpcSettings";
import { MPCDeviceDetectionHelper } from "../helpers/deviceDetectionHelper";
import { MPCComparePageButtonUrlUpdater } from "../helpers/comparePageButtonUrlUpdater";
const ScaleIcon2021 = require("../../images/scale-2021.svg") as string;
const ArrowRightIcon = require("../../images/arrow-right.svg") as string;

export module MPCFlyoutController {
    import ProductComparisonModelManager = MPCLocalStorageService.ProductComparisonModelManager;
    import IFlyoutController = MPCIFlyoutController.IFlyoutController;
    import StickyFlyOutNotificationService = MPCStickyFlyOutNotificationService.StickyFlyOutNotificationService;
    import MpcSettings = MpcSettingsModule.MpcSettings;
    import DeviceDetectionHelper = MPCDeviceDetectionHelper.DeviceDetectionHelper;
    import ComparePageButtonUrlUpdater = MPCComparePageButtonUrlUpdater.ComparePageButtonUrlUpdater;

    export class FlyoutController implements IFlyoutController {
        private static _instance: FlyoutController;
        private readonly scope: HTMLElement;
        private productComparisonModelManager: ProductComparisonModelManager;
        private pcfScopeRendered: boolean;
        private stickyFlyOutNotificationService: StickyFlyOutNotificationService;
        private deviceDetectionHelper: DeviceDetectionHelper;
        private comparePageButtonUrlUpdater: ComparePageButtonUrlUpdater;

        static get instance() {
            return this._instance || (this._instance = new this());
        }
        
        public constructor() {
            this.scope = document.querySelector('.pcf-scope');

            if(!this.scope){
                return;
            }

            this.init();
        }

        private init(): void {
            this.pcfScopeRendered = false;
            MpcSettings.instance.init(this.scope);

            this.deviceDetectionHelper = DeviceDetectionHelper.instance;

            if(this.deviceDetectionHelper.isMobileShop()) {
                this.prepareMobile();
            }

            this.productComparisonModelManager = ProductComparisonModelManager.instance;

            this.stickyFlyOutNotificationService = new StickyFlyOutNotificationService();
            this.stickyFlyOutNotificationService.init(this.scope);
            this.getCurrentProductsAmount();
            this.loadFlyoutIcons();

            this.registerEventHandlers();
        }

        private prepareMobile(): void {
            document.body.appendChild(this.scope);

            this.comparePageButtonUrlUpdater = ComparePageButtonUrlUpdater.instance;
            this.comparePageButtonUrlUpdater.init(this.scope);
        }

        private registerEventHandlers(): void {
            window.shell.subscribeTo("ManualProductComparison.ShowPcfScope", () => {
                this.showPcfScope();
            }, "ManualProductComparison.ShowPcfScope");

            window.shell.subscribeTo("ManualProductComparison.HidePcfScope", () => {
                this.hidePcfScope();
            }, "ManualProductComparison.HidePcfScope");

            const flyoutIconContainer: HTMLElement = this.scope.querySelector('.flyout-icon-container');
            flyoutIconContainer.addEventListener('click', () => { this.handleFlyoutIconClick(); });
        }

        private handleFlyoutIconClick(): void {
            window.shell.publishTo('ESMKT.HeadR.ToggleFlyout', 'ManualProductComparison');
        }

        private loadFlyoutIcons(): void {
            // ie11 fix, works on other browsers too, but ie11 is special :( 
            const flyoutIconContainer: HTMLElement = this.scope.querySelector('.flyout-icon-container');

            const scaleIcon: HTMLElement = document.createElement("div");

            if(flyoutIconContainer.classList.contains('mkt-headr-icon-wrapper')) {
                // mkt-headr-icon all styles comes from header:
                scaleIcon.className = "mkt-headr-icon mpc-scale";
            }
            else {
                scaleIcon.className = "flyout-icon mpc-scale";
            }

            scaleIcon.innerHTML = ScaleIcon2021;
            flyoutIconContainer.appendChild(scaleIcon);

            const productsCounter: HTMLElement = document.createElement("div");
            productsCounter.className = "products-counter";
            flyoutIconContainer.appendChild(productsCounter);
            // ---------------------------------------------------------------

            const compareButtonIcons: NodeListOf<HTMLElement> = this.scope.querySelectorAll('.compare-button-icon');
            const compareButtonIconsArray: Array<HTMLElement> = Array.prototype.slice.call(compareButtonIcons);

            for (const compareButtonIcon of compareButtonIconsArray) {
                compareButtonIcon.innerHTML = ArrowRightIcon;
            }
        }

        private getCurrentProductsAmount(): void {
             this.productComparisonModelManager.getModel().then(model => {
                 if (model.products.length > 0) {

                     // show Pcf Scope:
                     if (!this.deviceDetectionHelper.isMobileShop()) {
                         this.showPcfScope(true);
                     }
                 }
            });
        }

        private showPcfScope(renderWithoutAnimation: boolean = false): void {
            // check for existing pcf scope:
            if (!this.pcfScopeRendered) {
                this.scope.style.display = 'block';
                this.scope.classList.add('pcf-scope-position');

                if (renderWithoutAnimation)
                    this.scope.classList.add('pcf-no-animation');
                
                this.scope.classList.add('show-pcf-scope');
                this.pcfScopeRendered = true;
            }
        }

        hidePcfScope(): void {
            this.scope.classList.remove('pcf-no-animation');
            this.scope.classList.remove('show-pcf-scope');
            setTimeout(()=>{ this.scope.classList.remove('pcf-scope-position'); }, 400);
            setTimeout(()=>{ this.scope.style.display = 'none'; }, 400);
            this.pcfScopeRendered = false;
        }
    }
}

if(window.shell)
    MPCFlyoutController.FlyoutController.instance;