import { MPCDeviceDetectionHelper } from "../helpers/deviceDetectionHelper";

export module MPCStickyFlyOutNotificationService {
    import DeviceDetectionHelper = MPCDeviceDetectionHelper.DeviceDetectionHelper;
    
    export class StickyFlyOutNotificationService {
        private scope: HTMLElement;
        private deviceDetectionHelper : DeviceDetectionHelper;
        private maxProductsCountNotification: HTMLElement;
        private flyoutNewProductNotification: HTMLElement;
        private flyout: HTMLElement;
        private tabletHeaderHeightCalculated: number;

        public constructor() {
            this.deviceDetectionHelper = DeviceDetectionHelper.instance;
        }

        public init(scope: HTMLElement): void {
            this.scope = scope;
            this.tabletHeaderHeightCalculated = 38;
            const flyoutContainer: HTMLElement = this.scope.querySelector('.flyout-container');

            if (flyoutContainer && this.deviceDetectionHelper.isTabletHandHeldDevice()) {
                this.maxProductsCountNotification = flyoutContainer.querySelector('.max-products-count-notification');
                this.flyoutNewProductNotification = flyoutContainer.querySelector('.new-product-notification');
                this.maxProductsCountNotification.classList.add('sticky-flyout-notification');
                this.flyoutNewProductNotification.classList.add('sticky-flyout-notification');

                // todo: remove with ESPP-2482
                /* if(!mktHeaderOnline) {
                    this.flyout = flyoutContainer.querySelector('.flyout');
                    this.flyout.classList.add('sticky-flyout');

                    window.addEventListener('scroll', () => { this.handleFlyOutNotificationOnScroll() });
                    window.addEventListener('scroll', () => { this.handleFlyOutOnScroll() });

                    this.handleFlyOutOnScroll();
                } */

                this.handleFlyOutNotificationOnScroll();
                
            }
        }

        private handleFlyOutNotificationOnScroll(): void {
            if(this.tabletHeaderHeightCalculated >= window.pageYOffset) {
                this.maxProductsCountNotification.classList.remove("fixed");
                this.flyoutNewProductNotification.classList.remove("fixed");
            }
            else {
                this.maxProductsCountNotification.classList.add("fixed");
                this.flyoutNewProductNotification.classList.add("fixed");
            }
        }

        private handleFlyOutOnScroll(): void {
            if(this.tabletHeaderHeightCalculated >= window.pageYOffset) {
                this.flyout.classList.remove("fixed");
            }
            else {
                this.flyout.classList.add("fixed");
            }
        }
    }
}