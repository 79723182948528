export module MPCElementsHelper {
    export class HtmlElementsHelper {
        public static appendChildAtIndex(child: HTMLElement, index: number, parent: HTMLElement): void {
            if(index >= parent.children.length) {
                parent.appendChild(child);
            }
            else {
                parent.insertBefore(child, parent.children[index]);
            }
        }
    }
}