import { MPCIEventBus } from "../typings/iEventBus";

export module MPCEventBus {
    import IEventBus = MPCIEventBus.IEventBus;
    import EventName = MPCIEventBus.EventName;
    import CallbackLists = MPCIEventBus.CallbackLists;
    import ICallback = MPCIEventBus.ICallback;
    import ISubscription = MPCIEventBus.ISubscription;
    import ISubscriptionId = MPCIEventBus.ISubscriptionId;

    export class EventBus implements IEventBus {
        private static _instance: EventBus;
        private callbackLists: CallbackLists;

        private constructor() {
            this.callbackLists = [];
        }

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        subscribe(eventName: EventName | EventName[], callback: ICallback): ISubscriptionId[] {
            if (this.isArray(eventName)) {
                const subscriptionsIds: ISubscriptionId[] = [];

                for (const message of eventName) {
                    subscriptionsIds.push(this.createSubscription(message, callback));
                }

                return subscriptionsIds;
            } else {
                return [this.createSubscription(eventName, callback)];
            }
        }

        subscribeMultiple(subscriptions: ISubscription[]): ISubscriptionId[] {
            const subscriptionIds: ISubscriptionId[] = [];

            if (!subscriptions) {
                return subscriptionIds;
            }

            for (const subscription of subscriptions) {
                const subscriptionId: ISubscriptionId = this.addSubscription(subscription);

                subscriptionIds.push(subscriptionId);
            }
            return subscriptionIds;
        }

        unsubscribe(subscriptionId: ISubscriptionId | ISubscriptionId[]): void {
            if (this.isArray(subscriptionId)) {
                this.unSubscribeMultiple(subscriptionId);
            } else {
                this.removeSubscription(subscriptionId);
            }
        }

        publish(eventName: EventName, payload?: any): void {
            if (!this.callbackLists[<any>eventName]) {
                return;
            }

            // to prevent to also iterate over subscriptions, which are created while iterating
            const callbacksClone: ICallback[] = this.callbackLists[<any>eventName].slice(0);
            for (const callback of callbacksClone) {
                if (callback) {
                    callback(payload);
                }
            }
        }

        private createSubscription(eventName: EventName, callback: ICallback): ISubscriptionId {
            return this.addSubscription({
                eventName: eventName,
                callback: callback
            });
        }

        private addSubscription(subscription: ISubscription): ISubscriptionId {
            if (!this.callbackLists[<any>subscription.eventName]) {
                this.callbackLists[<any>subscription.eventName] = [];
            }

            const subscriptionId: ISubscriptionId = {
                eventName: subscription.eventName,
                id: (this.callbackLists[<any>subscription.eventName].push(subscription.callback)) - 1
            };

            return subscriptionId;
        }

        private unSubscribeMultiple(subscriptionIds: ISubscriptionId[]): void {
            for (const subscriptionId of subscriptionIds) {
                this.removeSubscription(subscriptionId);
            }
        }

        private removeSubscription(subscriptionId: ISubscriptionId): void {
            const subscription: ICallback = this.callbackLists[<any>subscriptionId.eventName][subscriptionId.id];
            if (subscription) {
                this.callbackLists[<any>subscriptionId.eventName][subscriptionId.id] = undefined;
            }
        }

        private isArray(element: any): element is any[] {
            return Array.isArray(element);
        }
    }
}