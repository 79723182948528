//product comparison flyout -
// this bundle will be included on category and detail pages 
// to support the flyout and the 'add' buttons with the scale symbol

import './typescript/errorhandler';
import './typescript/helpers/polyfills';
// PCF scss
import './styles/mpc-shared-basics.scss';
import './styles/flyout-icon.scss';
import './styles/flyout-icon.mobile.scss';
import './styles/flyout-content.scss';
import './styles/flyout-header.scss';
import './styles/flyout-footer.scss';
import './styles/containers-separation-line.scss';
import './styles/flyout-title.scss';
import './styles/flyout-title.mobile.scss';
import './styles/flyout-title.fragment.scss';
import './styles/flyout-notifications.scss';
import './styles/flyout-notifications.mobile.scss';
import './styles/flyout-new-product-notification.scss';
import './styles/flyout-new-product-notification.mobile.scss';
import './styles/flyout-empty-list-notification.scss';
import './styles/flyout-empty-list-notification.mobile.scss';
import './styles/flyout-max-products-count-notification.scss';
import './styles/flyout-max-products-count-notification.mobile.scss';
import './styles/flyout-compare-list-btn.scss';
import './styles/flyout-compare-list-btn.mobile.scss';

//TP scss
import './styles/touch-point-toolbar.scss';
import './styles/touch-point-article-tile.scss';
import './styles/touch-point-article-tile.mobile.scss';
import './styles/touch-point-details-page.scss';
import './styles/touch-point-details-page.mobile.scss';

// TP ts
import './typescript/TP/TouchPointFactory';

// PCF ts
import './typescript/PCF/flyout-controller';
