import { MPCEventBus } from "../helpers/event-bus";
import { MpcSettingsModule } from "../helpers/MpcSettings";
import { MPCLocalStorageService } from "../local-storage-service";
const ScaleIcon = require("../../images/scale-2021.svg") as string;

export module MPCMobileToolbarButton {
    import EventBus = MPCEventBus.EventBus;
    import MpcSettings = MpcSettingsModule.MpcSettings;
    import ProductComparisonModelManager = MPCLocalStorageService.ProductComparisonModelManager;

    export class MobileToolbarButton  {
        private static _instance: MobileToolbarButton;
        private eventBus: EventBus;
        private toolbarButtons: NodeListOf<HTMLElement>;
        private compareButtons: HTMLElement[];
        private tbw_mlt: string;

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        private constructor() {
            this.eventBus = EventBus.instance;
            document.addEventListener("DOMContentLoaded", () => {
                this.initialize();
            });
        }

        private initialize() {
            this.toolbarButtons = document.querySelectorAll(".mpc-scope .toolbar-button-wrapper");
            if (!this.toolbarButtons || !this.toolbarButtons.length) {
                return;
            }

            this.compareButtons = new Array();

            // for TouchPoint Accessibility:
            if(this.toolbarButtons[0].children && this.toolbarButtons[0].children.length > 0) {
                this.tbw_mlt = this.toolbarButtons[0].children[0].innerHTML;
            }

            this.toolbarButtons.forEach((toolbarButton: HTMLElement) => {
                // compareButton exists only in legacy shop, all other places contains toolbar-button-wrapper
                if (toolbarButton.closest('.compare-button'))
                    this.compareButtons.push(toolbarButton.closest('.compare-button'));
                else
                    this.compareButtons.push(toolbarButton);

                toolbarButton.querySelector('.comparison-icon').innerHTML = ScaleIcon;
                toolbarButton.addEventListener("click", () => {
                    // New category page:
                    window.shell.publishTo('ArticleTileService.Mobile.ToggleTouchPointOnTile', undefined);

                    // Legacy category page, remove later!!!: 
                    this.eventBus.publish("mobile-toolbar-button.clicked");
                    let categoryNavKey: string;
                    if (MpcSettings.instance.MobileCategoryNavKey) {
                        categoryNavKey = MpcSettings.instance.MobileCategoryNavKey;
                    }

                    this.compareButtons.forEach((compareButton: HTMLElement) => {
                        if (compareButton.classList.contains('active')) {
                            compareButton.classList.remove('active');
                            if (categoryNavKey) {
                                ProductComparisonModelManager.instance.deactivateOnMobileCategory(categoryNavKey);
                            }
                        } else {
                            compareButton.classList.add('active');
                            if (categoryNavKey) {
                                ProductComparisonModelManager.instance.activateOnMobileCategory(categoryNavKey);
                            }
                        }
                    });
                });

                const categoryNavKey = MpcSettings.instance.MobileCategoryNavKey;
                if (categoryNavKey && ProductComparisonModelManager.instance.isActivatedOnMobileCategory(categoryNavKey)) {
                    this.eventBus.publish("mobile-toolbar-button.clicked");
                    this.compareButtons[this.compareButtons.length-1].classList.add('active');
                }
            });
        }

        public getTBW_Mlt(): string {
            return this.tbw_mlt;
        }
    }
}